import React, { useState } from "react";
import db from "./firebase";
import './Metadata.css';
import { useForm } from 'react-hook-form'



function MetaData() {
  let likes = 1;

  const { register, handleSubmit, reset } = useForm();

  const [newVideo, setNewVideo] = useState("");

  const [message, setMessage] = useState("");

  // let [videoData, setVideoData] = useState([]);

  function onSubmit(data) {
    // setVideoData = JSON.parse(JSON.stringify(data));
    // console.log(videoData);
    db.collection("videos").add({
      title: data.title,
      url: data.url,
      thumbnail: data.thumbnail,
      source: data.source,
      likes: likes,
      webUrl: data.webUrl, 
      twitterUrl: data.twitterUrl, 
      facebookUrl: data.facebookUrl, 
      instagramUrl: data.instagramUrl, 
      youtubeUrl: data.youtubeUrl
    })
    .then((docRef) => {
        // reset()
        setNewVideo("success");
        setMessage(docRef.id);
    })
    .catch((error) => {
        setNewVideo("failure");
        setMessage(error);
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="metaDataPage">
          <h1>Add a new video to the Database</h1>
            <div>
              {(newVideo === "success") ? (
                <p className="success">Alhamdulillah the new video with ID "{message}" has been added to the database.</p> )
              : ""}
              {(newVideo === "failure") ? (
                <p className="failure">There was a problem: {message}</p> )
              : ""}
            </div>
          <div className="form-group">
            <label htmlFor="title">Title<span className="asterisk">*</span></label>
            <input type="text" id="title" className="form-control" required {...register('title')}/>
          </div>
          <div className="form-group">
            <label htmlFor="url">URL<span className="asterisk">*</span></label>
            <input type="text" id="url" className="form-control" required {...register('url')}/>
          </div>
          <div className="form-group">
            <label htmlFor="thumbnail">Thumbnail URL</label>
            <input type="text" id="thumbnail" className="form-control" {...register('thumbnail')}/>
          </div>
          <div className="form-group">
            <label htmlFor="source">Source</label>
            <input type="text" id="source" className="form-control" {...register('source')}/>
          </div>
          <div className="form-group">
            <label htmlFor="webUrl">Web Url</label>
            <input type="text" id="webUrl" className="form-control" {...register('webUrl')}/>
          </div>
          <div className="form-group">
            <label htmlFor="twitterUrl">Twitter Url</label>
            <input type="text" id="twitterUrl" className="form-control" {...register('twitterUrl')}/>
          </div>
          <div className="form-group">
            <label htmlFor="facebookUrl">Facebook Url</label>
            <input type="text" id="twitterUrl" className="form-control" {...register('facebookUrl')}/>
          </div>
          <div className="form-group">
            <label htmlFor="instagramUrl">Instagram Url</label>
            <input type="text" id="instagramUrl" className="form-control" {...register('instagramUrl')}/>
          </div>
          <div className="form-group">
            <label htmlFor="youtubeUrl">YouTube Url</label>
            <input type="text" id="youtubeUrl" className="form-control" {...register('youtubeUrl')}/>
          </div>
          <button type="submit">Submit</button>
          <a href="/" className="link-home">Home</a>
      </div>
    </form>
  );
}

export default MetaData;
