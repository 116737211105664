
import React, { useState, useEffect } from "react";

import './App.css';




function NotFound() {
  
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    const timerdelay = setTimeout(
      () =>
        setNotFound(true),
      1000,
    );
     return() => clearTimeout(timerdelay);

  }, [])

  return (
   
      <div className="NotfoundPage">
          {notFound ? (
              <div>
              <h1 >Sorry, the video you are looking for cannot be found.</h1>
              <a href="/">Home</a>
            </div>
          ) : (
            <div className="loading">
             <div className="spin"></div>
            </div>
          )}

      </div>

  );
}

export default NotFound;
