import React from "react";
import './VideoFooter.css';
import VideoSidebar from './VideoSidebar';

// import ThumbUpIcon from '@material-ui/icons/ThumbUp';
// import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
// import MessagesIcon from "@material-ui/icons/Message";
// import ShareIcon from "@material-ui/icons/Share";

// import db from "./firebase";
// import firebase from "firebase";
import {
    Link
  } from "react-router-dom";

  import useAnalyticsEventTracker from './useAnalyticsEventTracker';

function VideoFooter ({ title, likes, messages, share, id, playing, source, AllVideos, webUrl, twitterUrl,  facebookUrl, instagramUrl, youtubeUrl}) {
    // const [liked, setLiked] = useState(false);

    const gaEventTracker = useAnalyticsEventTracker('Clips Source footer');

    // const likeFn = (boolean) => {
    //     if(boolean) {
    //         setLiked(boolean);
    //         db.collection("videos").doc(id).update({
    //             likes: (firebase.firestore.FieldValue.increment(1))
    //         }); 
    //     } else {
    //         setLiked(boolean);
    //         db.collection("videos").doc(id).update({
    //             likes: (firebase.firestore.FieldValue.increment(-1))
    //         });
    //     }
 
    // }

    return (
        <div className="videoFooter">
            <div className="footer-icons">
                <div className="videoFooter__text">
                    {/* <VideoSidebar source={source} webUrl={webUrl} twitterUrl={twitterUrl} facebookUrl={facebookUrl} instagramUrl={instagramUrl} youtubeUrl={youtubeUrl}/> */}
                    {AllVideos === "false" ? ( 
                        <p><span className="textNolink">@{source}</span></p>
                    ) : (
                        source !== "" && source !== undefined ? 
                            (<p>
                                <Link to={source} onClick={()=>gaEventTracker(source)}>
                                    <img src={`images/${source}.png`} className="userIcon" alt={`${source} icon`} width="35" height="35"/>
                                    <span className="link-text">@{source}</span>
                                </Link>
                            </p>) : ""
                        )}
                    <p>{title}</p>
                </div>


                {/* <div className="videoFooter__button">
                    {liked ? (
                        <ThumbUpIcon 
                            fontSize="large"
                            onClick={() => likeFn(false)} 
                        />
                    ) : (
                        <ThumbUpOutlinedIcon 
                            fontSize="large" 
                            onClick={() => likeFn(true)} 
                        />
                    )}
                    <p>{likes}</p>
                </div> */}

                <div className="videoFooter__button">
                    {playing ? (
                        <PauseIcon 
                            fontSize="large"
                        />
                    ) : (
                        <div className="hiddenPlayContainer"></div>
                    )}
                </div>
            </div>
            <div className="footer-text more-links">
                
            </div>
            {/* <div className="footer-text">
                <p>@JalsaConnect</p>
                <p>#JalsaConnect</p>
            </div>
            <div className="videoFooter__button">
                <MessagesIcon fontSize="large" />
                <p>{messages}</p>
            </div>

            <div className="videoFooter__button">
                <ShareIcon fontSize="large" />
                <p>{share}</p>
            </div> */}
        </div>
    );
}

export default VideoFooter;
