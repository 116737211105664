import React, {useEffect} from "react";
import './App.css';
import AllVideos from "./AllVideos";
import SomeVideos from "./SomeVideos";
import MetaData from "./MetaData";
import ReactGA from 'react-ga';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {

  const TRACKING_ID = "UA-203021329-1"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<AllVideos />} />
          <Route path="/add-new-video-please" element={<MetaData />} />
          <Route path="/:source" element={<SomeVideos />} />
        </Routes>
      </Router>
    </div>
  )
}
export default App;
