import React, { useState, useEffect } from "react";
import Video from './Video';
import db from "./firebase";
import './App.css';


function AllVideos() {
  const [videos, setVideos] = useState([]);
  const [number, setNumber] = useState();
  const [currentVideos, setCurrentVideos] = useState([]);
  const [videoStarted, setVideoStarted] = useState(false);
  const [scrollToSpecificVideo, setScrollToSpecificVideo] = useState("");
  let loadtrigger = "";
  

  //shuffle array method that takes an array and moves the objects around
  function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
      }
  }

  //run on page load once only to get list of all videos
  useEffect(() => {
    const unsubscribe = db.collection("videos").onSnapshot((snapshot) =>
      setVideos(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})))
    );
    return () => {
      unsubscribe();
    }
  }, []);


  //run when video has been updated so once initially. 
  //1: runs shuffle method (as video array has been populated) and randomises the videos array
  //2: setnumber to 3 so this is to say how many videos to display initially = 3
  //3: populate setCurrentVideos array with 3 videos - use filter method and add video 0 to video number (3)
  useEffect(() => {
    shuffleArray(videos);
    setNumber(3)
    setCurrentVideos(videos.filter((item, index) => index < 3 && index >= 0))
    return () => {
      setNumber();
      setCurrentVideos([]);
    }
  }, [videos]);


  //we pass the last video id to video component as loadtrigger. Once the intersecting video is the last video, we call loadmore method
  function loadmore() {
    //populate addMoreVideos with the next 3 videos using filter method so first time from number(3) to number+3(6). At the end we setNumber to number+3 so the seond time it will be from number(6) to number+3(9)
    let addMoreVideos = videos.filter((item, index) => index < number+3 && index >= number)

    //append to currentVideos array 3 new videos that we got from the above filter method
    currentVideos.push(...addMoreVideos);

    //Video.js had a useeffect that is looking at category but was trigering for each video component load and it was scrolling to top for when we navigate to a different page. This is set to when we load more videos, that function should scroll to the last/current item and not shift anywhere else
    if(currentVideos[(number-1)] !== undefined) {
      setScrollToSpecificVideo(currentVideos[(number-1)].id);
    }

    //now that we have added 3 videos to the currentVideos array, we can update the number so it is number+3 for next time. First time it will be 6 then 9 and so on...
    setNumber(number + 3);

  }

  function callSetVideoStarted() {
    setVideoStarted(true);
  }
  
  //once current video is populated, get the last item and set that to loadtrigger variable
  //first time this will be number(3)-1 = 2 so currentVideos[2]
  //once we run load more method, the number will be number+3 so the second time this will be number(6)-1 so currentVideos[5]
  if(currentVideos[(number-1)] !== undefined) {
    loadtrigger = currentVideos[(number-1)].id;
  }

  return (
    <div className="app">
        <div className="app__videos">
          {currentVideos.map(
            ({ title, likes, messages, shares, url, id, thumbnail, source, webUrl, twitterUrl, facebookUrl, instagramUrl, youtubeUrl }, index) => (
              <Video
                key={id}
                index={index}
                id={id}
                title={title}
                likes={likes}
                messages={messages}
                shares={shares}
                url={url}
                thumbnail={thumbnail}
                source={source}
                loadtrigger={loadtrigger}
                loadmore={loadmore}
                scrollToSpecificVideo={scrollToSpecificVideo}
                AllVideos={true}
                videoStarted={videoStarted}
                number={number}
                callSetVideoStarted={callSetVideoStarted}
                webUrl={webUrl}
                twitterUrl={twitterUrl}
                facebookUrl={facebookUrl}
                instagramUrl={instagramUrl}
                youtubeUrl={youtubeUrl}
              />
            )
          )}
        </div>
    </div>
  );
}

export default AllVideos;
