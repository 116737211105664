import React from "react";
import './App.css';

import PublicIcon from '@material-ui/icons/Language';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';






import useAnalyticsEventTracker from './useAnalyticsEventTracker';

function VideoSidebar ({ source, webUrl, twitterUrl, facebookUrl, instagramUrl, youtubeUrl }) {

    const gaEventTracker = useAnalyticsEventTracker('Sidebar link source:' + {source});



    return (
        <div className="videoSidebar">
            <div className="sidebar-icons">
                {webUrl ? (
                    <div>
                        <a href={webUrl} onClick={()=>gaEventTracker(webUrl)} target="_blank" rel="noopener noreferrer">                    
                            <PublicIcon 
                            fontSize="large"
                            className="linkIcons"
                            /></a>
                    </div>
                    ) : ""
                }
                {twitterUrl ? (
                    <div>
                        <a href={twitterUrl} onClick={()=>gaEventTracker(twitterUrl)} target="_blank" rel="noopener noreferrer">                    
                            <TwitterIcon 
                            fontSize="large"
                            className="linkIcons"
                            /></a>
                    </div>
                    ) : ""
                }
                {facebookUrl ? (
                    <div>
                        <a href={facebookUrl} onClick={()=>gaEventTracker(facebookUrl)} target="_blank" rel="noopener noreferrer">                    
                            <FacebookIcon 
                            fontSize="large"
                            className="linkIcons"
                            /></a>
                    </div>
                    ) : ""
                }
                {instagramUrl ? (
                    <div>
                        <a href={instagramUrl} onClick={()=>gaEventTracker(instagramUrl)} target="_blank" rel="noopener noreferrer">                    
                            <InstagramIcon 
                            fontSize="large"
                            className="linkIcons"
                            /></a>
                    </div>
                    ) : ""
                }
                {youtubeUrl ? (
                    <div>
                        <a href={youtubeUrl} onClick={()=>gaEventTracker(youtubeUrl)} target="_blank" rel="noopener noreferrer">                    
                            <YoutubeIcon 
                            fontSize="large"
                            className="linkIcons"
                            /></a>
                    </div>
                    ) : ""
                }
            </div>
        </div>
    );
}

export default VideoSidebar;
