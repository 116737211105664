// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyAQ86hQ28KQpCdafWjQqQBlNgOxp7qTWaY",
    authDomain: "video-app-f9af3.firebaseapp.com",
    projectId: "video-app-f9af3",
    storageBucket: "video-app-f9af3.appspot.com",
    messagingSenderId: "928595032101",
    appId: "1:928595032101:web:dde9ad585dbb823b23e7cc",
    measurementId: "G-X9TCLZQ6CJ"
  };
  
  const firebaseApp = firebase.initializeApp(firebaseConfig);

  const db = firebaseApp.firestore();

  export default db;
