import React, { useRef, useState, useEffect } from "react";
import './Video.css';
import VideoFooter from './VideoFooter';
import VideoHeader from "./VideoHeader";
import VideoSidebar from "./VideoSidebar";
import PlayIcon from '@material-ui/icons/PlayArrowRounded';
import { useParams } from 'react-router-dom'


function Video({title, 
    likes, 
    messages, 
    shares, 
    url, 
    id, 
    index,
    thumbnail, 
    source, 
    loadtrigger, 
    loadmore, 
    scrollToSpecificVideo, 
    AllVideos, 
    videoStarted, 
    number, 
    callSetVideoStarted,                 
    webUrl,
    twitterUrl,
    facebookUrl,
    instagramUrl,
    youtubeUrl}) {

  const [playing, setPlaying] = useState(false);

  const videoRef = useRef(null);

  let { category } = useParams();


  const onVideoPress = () => {
      if (playing) {
        videoRef.current.pause();
        setPlaying(false);
      } else {
        videoRef.current.play();
        setPlaying(true);
        callSetVideoStarted();
      }
  }

  // on each video end, scroll to the next video automatically
  const onEnded = () => {
    let videos = document.getElementsByTagName('video');

    if(videos.length === index+1) {
      videos[0].scrollIntoView({ block: 'start',  behavior: 'smooth' });
    } else {
      videos[index + 1].scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  //when we load more videos, scroll to the current video instead of jumping to another video
  //when we go to a different page then scroll to top
  useEffect(() => {
    if(scrollToSpecificVideo !== "") {
      document.getElementById(scrollToSpecificVideo).scrollIntoView({ block: 'start',  behavior: 'smooth' });
    } else {
      document.getElementsByTagName('video')[0].scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }, [category, scrollToSpecificVideo]);


  useEffect(() => {
    let options = {
      rootMargin: "0px",
      threshold: [0.5, 0.5]
    };

    //start playing video when we are intersecting it and pause video when we are not intersecting it and update setPlaying variable accordingly
    let handlePlay = (entries) => {
        entries.forEach((entry) => {
          //call load more function in parent component when entry is intersecting and we are on the last video that has been loaded. Last video ID is coming from the parent component
          if(entry.isIntersecting && videoRef.current.id === loadtrigger) {
            loadmore();
            
          }


          const playPromise = videoRef.current.play();

          if (playPromise !== undefined) {
            playPromise.then(_ => {
              if (entry.isIntersecting && videoStarted) {
                videoRef.current.play();
                setPlaying(true);
              } else {
                videoRef.current.pause();
                setPlaying(false);
              }
            })
            .catch(error => {
              console.log("Error: " + error)
            });
          }

        });
    };
    let observer = new IntersectionObserver(handlePlay, options);

    observer.observe(videoRef.current);
    return () => {
      observer.disconnect();
    }
  }, [loadtrigger, loadmore, videoStarted]);


  return (
    <div className="video" id={id}>
        <VideoHeader />
        <video
            className="video__player"
            // loop
            // muted
            // autoplay="autoplay"
            // controls
            playsInline
            onClick={onVideoPress}
            onEnded={onEnded}
            // onPlay={onPlay}
            ref={videoRef}
            src={url}
            id={id}
            key={id}
            poster={thumbnail}
        ></video>
        {!playing ? (
            <PlayIcon 
                fontSize="large"
                className="playIcon"
                onClick={onVideoPress}
            />
        ) : ""}
        <VideoSidebar source={source} webUrl={webUrl} twitterUrl={twitterUrl} facebookUrl={facebookUrl} instagramUrl={instagramUrl} youtubeUrl={youtubeUrl}/>
        <VideoFooter title={title} likes={likes} messages={messages} shares={shares} id={id} playing={playing} source={source} AllVideos={AllVideos}/>

    </div>
  );
}

export default Video;
