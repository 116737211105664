import React from "react";
import './VideoHeader.css';
import HomeIcon from '@material-ui/icons/Home';

import {
    Link
  } from "react-router-dom";
import useAnalyticsEventTracker from './useAnalyticsEventTracker';


function VideoHeader () {

    const gaEventTracker = useAnalyticsEventTracker('Clips Source');

    return (
        <div className="videoHeader">
            {/* <p>@JalsaConnect</p> */}
            <Link to="/" onClick={()=>gaEventTracker("Top home link")}>
                    <HomeIcon 
                            fontSize="large"
                            className="homeIcon"
                        />
                {/* <img 
                    className="jclogo"
                    src="images/jc-icon.png"
                    alt="Jalsa Connect logo" 
                /> */}
            </Link>
            {/* <p>#JalsaConnect</p> */}
        </div>
    );
}

export default VideoHeader;
